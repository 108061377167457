@import 'antd/dist/antd.css';

.header-order-div{
  background-color:#f8f8f8;
}
.header-div{
  background-color: white;
  border-bottom: 1px solid;
  
  padding: 10px;
  box-shadow: 0px 2px #888888;
}
/* */
.ant-layout-header {
  padding:0;
  height: 64px;
}
.brand-logo{
  height:62px;
  margin-top:-2vh;
}

.header-avatar{
  
  margin: 3px 0px 4px 8px;
}
.logged-user{
  float:right;
}
/* .search-box{
  margin-top: -1vh;
} */
.ant-input-search{
  margin-top:2vh;
 }
.nav-menu-collapse {
  margin-left: 2vh;
}
.ant-input-affix-wrapper-lg {
  border-radius: 40px;
}
.ant-input:hover{
  border: 1px solid transparent;
}

@media screen and (max-width:900px) and (min-width:600px) {
  .ant-col-6{
    flex: 0 0 15%;
  }
  .ant-col-3{
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }  
  
  .search-container {
    flex: 0 0 25%;
  }
  .dropdown-menu{
    flex: 0 0 4.33333%;
    max-width: 4.33333%;
  }
} 
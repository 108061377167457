.tabs-list{
    list-style: none;
    display: inline-flex;
    width: 100vw;
    padding: 10px 10px 0px;
    border-bottom: 1px solid black;
    font-family: system-ui;
    
}
.tabs-list li{
    padding: 0px 10px 0px 10px;
    
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    text-shadow: 0 0 0.25px currentColor;
}
  .dashboard-wrapper {
    margin-top: 5vh;
    padding: 0px 30px 0px 30px;
}
.ant-tabs {
    width: -webkit-fill-available;
}
.ant-table{
    line-height: 1.5;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    
    padding: 7px;
 
}

.ant-space-align-center {   
    margin: 0 auto !important;
}
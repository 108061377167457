.right{
    float: right;   
}
.footer_link{
    color: rgba(0, 0, 0, 0.85);
}
@media screen and (max-width:900px) and (min-width:600px) {
    .ant-col-1{
        flex:0 0 8.33333333%;
        max-width: 8.33333333%;
    }
    .ant-col-2{
        flex:0 0 12.5%;
        max-width: 12.5%;
    }
}